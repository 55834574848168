require("normalize.css/normalize.css");
require("./page.scss");

import { MDCRipple } from "@material/ripple";

const navigateTeamDetailsBtn = document.querySelector("#navigateTeamDetails");
const linkToBeonderBtn = document.querySelector("#linkToBeonder");

//include ripple effect
new MDCRipple(navigateTeamDetailsBtn);
new MDCRipple(linkToBeonderBtn);

function setInternalNavigation(pageName) {
  self.location.href = self.location.origin + "/" + pageName;
}

//register btn actions

navigateTeamDetailsBtn.addEventListener("click", (evt) => {
  evt.preventDefault();
  setInternalNavigation("team.html");
});

linkToBeonderBtn.addEventListener("click", (evt) => {
  evt.preventDefault();
  setInternalNavigation("policy.html");
  //window.location.href = "https://audit.beonder.com/account/login";
});
